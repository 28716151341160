<template>
  <AdminReportingForm
    title="Facility Changeover"
    description="The Facility Changeover Report is at the park level and provides a visual
      representation of all spots in the selected park along with that spot’s
      inventory status for the date period being reported. This report can be run for any 14 day date range."
    file="FacilityChangeover$"
    :showProduct="true"
    :showSortBy="true"
    :reportStatus="reportStatus"
    :submitting="submitting"
    maxLength="14"
    @getReport="getReport"
  />
</template>

<script>
import AdminReportingForm from "@/components/admin/AdminReportingForm.vue";
import AdminReportingService from "@/services/admin/AdminReportingService.js";
import moment from "moment";

export default {
  name: "FacilityChangeoverReport",
  title: "Admin - Facility Changeover Report",
  data() {
    return {
      reportStatus: "",
      submitting: false
    };
  },
  components: {
    AdminReportingForm
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    getReport(reportData) {
      this.reportStatus = "";
      this.submitting = true;
      const date = moment();
      reportData.dateRun = date.format("MM/DD/YYYY hh:mm:ss A");

      const service = new AdminReportingService(this.tenantId);
      service
        .getFacilityChangeoverReport(reportData)
        .then(response => {
          if (response.deliveryMethod == "Download") {
            const filename = reportData.filename.replace(
              "$",
              date.format("MM_DD_YYYY_hh_mm_ss_A")
            );
            service.forceFileDownload(response, filename);
          } else {
            this.reportStatus = "Report sent!";
          }
        })
        .finally(() => {
          this.submitting = false;
        });
    }
  }
};
</script>
